$('.sesion a').click(function(e){
    e.preventDefault();

    var disp = $(this).parent().children('div').css('display');
    $('.temario').each(function(index) {
        $(this).slideUp();
    });
    $('.ui-2_square-delete-10').each(function(index) {
        $(this).removeClass('ui-2_square-delete-10');
        $(this).children().addClass('ui-2_square-add-08');
    });
    if (disp == 'none') {
        $(this).removeClass('ui-2_square-add-08');
        $(this).children().addClass('ui-2_square-delete-10');
        $(this).parent().children('div').animate({height: "toggle"}, 300);
    }
});

$('.como_funciona a').click(function(e){
    e.preventDefault();

    var disp = $(this).parent().children('div').css('display');
    $('.funciona').each(function(index) {
        $(this).slideUp();
    });
    $('.ui-2_square-delete-10').each(function(index) {
        $(this).removeClass('ui-2_square-delete-10');
        $(this).children().addClass('ui-2_square-add-08');
    });
    if (disp == 'none') {
        $(this).removeClass('ui-2_square-add-08');
        $(this).children().addClass('ui-2_square-delete-10');
        $(this).parent().children('div').animate({height: "toggle"}, 300);
    }
});

$('.c_funciona a').click(function(e){
    e.preventDefault();

    var disp = $(this).parent().children('div').css('display');
    $('.c_f').each(function(index) {
        $(this).slideUp();
        $(this).parent().removeClass('c_funciona2');
        $(this).parent().addClass('c_funciona');
    });
    $('.ui-1_simple-delete').each(function(index) {
        $(this).removeClass('ui-1_simple-delete');
        $(this).children().addClass('ui-1_simple-add');
    });
    if (disp == 'none') {
        $(this).parent().removeClass('c_funciona');
        $(this).parent().addClass('c_funciona2');
        $(this).removeClass('ui-1_simple-add');
        $(this).children().addClass('ui-1_simple-delete');
        $(this).parent().children('div').animate({height: "toggle"}, 300);
    }
});

$('.cont_update > a').click(function(e){
    e.preventDefault();

    var disp = $(this).parent().children('div').css('display');
    $('.desple_update').each(function(index) {
        $(this).slideUp();
        $(this).parent().removeClass('cont_update2');
        $(this).parent().addClass('cont_update');
    });
    $('.ui-1_simple-delete').each(function(index) {
        $(this).removeClass('ui-1_simple-delete');
        $(this).children().addClass('ui-1_simple-add');
    });
    if (disp == 'none') {
        $(this).parent().removeClass('cont_update');
        $(this).parent().addClass('cont_update2');
        $(this).removeClass('ui-1_simple-add');
        $(this).children().addClass('ui-1_simple-delete');
        $(this).parent().children('div').animate({height: "toggle"}, 300);
    }
});



$(document).on("click",".menu_cuenta_btn",function(e){
    e.preventDefault();
    $(".desple_menu_cuenta").toggle();
    var i = $(this).find('i');
    if (i.hasClass('arrows-1_minimal-down')) $(this).find('i').removeClass('arrows-1_minimal-down').addClass('arrows-1_minimal-up');
    else $(this).find('i').removeClass('arrows-1_minimal-up').addClass('arrows-1_minimal-down');
});

$(document).on("click",".menu_btn",function(e){
    e.preventDefault();
    $(".menu_sub").toggle();
    var i = $(this).find('i');
    if (i.hasClass('arrows-1_minimal-down')) $(this).find('i').removeClass('arrows-1_minimal-down').addClass('arrows-1_minimal-up');
    else $(this).find('i').removeClass('arrows-1_minimal-up').addClass('arrows-1_minimal-down');
});

$(document).on("click",".otras_ciudades_btn",function(e){
    e.preventDefault();
    $(".desple_otras_ciudades").toggle();
    var i = $(this).find('i');
    if (i.hasClass('arrows-1_minimal-down')) $(this).find('i').removeClass('arrows-1_minimal-down').addClass('arrows-1_minimal-up');
    else $(this).find('i').removeClass('arrows-1_minimal-up').addClass('arrows-1_minimal-down');
});

$(document).on("click",".btn_desple_tematicas",function(e){
    e.preventDefault();
    $(this).parent().children('ul').toggle();
    var i = $(this).find('i');
    if (i.hasClass('arrows-1_minimal-down')) $(this).find('i').removeClass('arrows-1_minimal-down').addClass('arrows-1_minimal-up');
    else $(this).find('i').removeClass('arrows-1_minimal-up').addClass('arrows-1_minimal-down');
});


$(document).on("click",".mostrar_responder",function(e){
    $(this).closest('.respuesta').addClass('responder_desplegado');
});

$(document).on("click",".sesiones > a",function(e){
    $(this).toggleClass('f-gris5');
    $(this).toggleClass('f-blanco');
    e.preventDefault();
    $(this).parent().children('div').toggle();
    var i = $(this).find('i');
    if (i.hasClass('ui-1_simple-add')) $(this).find('i').removeClass('ui-1_simple-add').addClass('ui-1_simple-delete');
    else $(this).find('i').removeClass('ui-1_simple-delete').addClass('ui-1_simple-add');

});




$('.cont_desple > a').click(function(e){
    $(this).parent().children('div').slideToggle(300);
    return false;
});
$('.cerrar > a').click(function(e){
    $(this).parent().parent().parent().slideToggle(300);
    return false;
});

(function ($) {
    this.showContent = function () {
        element = document.getElementById("datos");
        elementDNI = document.getElementById("datosDNI");
        check = document.getElementById("check1");
        if (check.checked) {
            element.style.display='block';
            if (elementDNI != null) elementDNI.style.display='none';
        }
        else {
            element.style.display='none';
            if (elementDNI != null) elementDNI.style.display='block';
        }
    };

    return this;

})(jQuery);




$(document).ready(function(){
    $('input[type="radio"]').click(function(){
        if($(this).attr("value")=="particular"){
            $(".box").not(".particular").hide();
            $(".particular").show();
            $('.empres input').val('');//Vaciamos el que no es
        }
        if($(this).attr("value")=="empresa"){
            $(".box").not(".empresa").hide();
            $(".empresa").show();
            $('.particular input').val('');//Vaciamos el que no es
        }
    });
});

$(document).ready(function(){
    let visibleService = window.location.hash.substr(1);
    if(visibleService !== ''){
        $('#'+visibleService).closest('li').nextAll().toggle();
        hideServicesExcept(visibleService);
    }
});

$('.project').on('click', function(e){
    e.preventDefault();
    $(this).closest('li').nextAll().toggle();
    let visible = $(this).next().css('display');
    if(visible !== 'none'){
        changeArrowDirection($(this), 'up');
        hideServicesExcept($(this).attr('id'));
    } else {
        changeArrowDirection($(this), 'down');
    }
});

function hideServicesExcept(visibleService)
{
    $('.project').each(function(){
        if($(this).attr('id') !== visibleService){
            $(this).closest('li').nextAll().hide();
            changeArrowDirection($(this), 'down');
        } else {
            $(this).closest('li').nextAll().show();
            changeArrowDirection($(this), 'up');
        }
    });
}

function changeArrowDirection(element, direction)
{
    if(direction === 'up') $(element).find('i').removeClass('arrows-1_minimal-down').addClass('arrows-1_minimal-up');
    if(direction === 'down') $(element).find('i').removeClass('arrows-1_minimal-up').addClass('arrows-1_minimal-down');
}
