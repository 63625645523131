/**
 * Created by david on 22/03/16.
 */

is_processing = false;
last_page = false;

function addMoreElements() {
    //return;
    is_processing = true;
    var route = $('ul.scroll-infinite').attr('data-route');
    var current_children = $('ul.scroll-infinite').children().size();

    $.ajax({
        type: "POST",
        //FOS Routing
        url: route,
        data: {current_children: current_children},
        success: function(data) {
            if (data.html.length > 0) {
                $('.infinite-scroll').append(data.html);
                page = page + 1;
                //The server can answer saying it's the last page so that the browser doesn't make anymore calls
                last_page = data.last_page;
            } else {
                last_page = true;
            }
            is_processing = false;
        },
        error: function(data) {
            is_processing = false;
        }
    });
}

$(window).scroll(function() {
    var wintop = $(window).scrollTop(), docheight = $(document).height(), winheight = $(window).height();
    //Modify this parameter to establish how far down do you want to make the ajax call
    var scrolltrigger = 0.80;
    if ((wintop / (docheight - winheight)) > scrolltrigger) {
        //I added the is_processing variable to keep the ajax asynchronous but avoiding making the same call multiple times
        if (last_page === false && is_processing === false) {
            //addMoreElements();
        }
    }
});