/**
           Temáticas desplegables
       **/
       $('.desple span').click(function(e){
           e.preventDefault();
          if ($(this).parent().find(".mas").hasClass("mas")) {
               $(this).parent().find(".mas").removeClass("mas").addClass('menos');
               $(this).parent().find( "ul" ).slideDown();
          } else {
               $(this).parent().find(".menos").removeClass("menos").addClass('mas');
               $(this).parent().find( "ul" ).slideUp();
           }

       });

       $('.filtro_avanzado_btn').click(function(e){
           e.preventDefault();
           $(".desplegable_filtro_avanzado").toggle();
           var i = $(this).find('i');
           if (i.hasClass('arrows-1_minimal-down')) $(this).find('i').removeClass('arrows-1_minimal-down').addClass('arrows-1_minimal-up');
           else $(this).find('i').removeClass('arrows-1_minimal-up').addClass('arrows-1_minimal-down');
       });

