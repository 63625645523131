$(document).ready(function() {

    /**
     * desplegables carrito, notificacione, usuario
     */
    $('.carrito').click(function(e){
        // si ya está visible no hace falta hacer nada
        if ($('.desple-carrito').css('display')!='none') return;
        e.stopPropagation();
        $('.desple-mi-cuenta').slideUp(function() {
            $('.desple-notificaciones').slideUp(function() {
                $('.desple-multi-lang').animate({height: "hide"}, 300);
                $('.desple-carrito').animate({height: "toggle"}, 300);
            });
        });
    });

    // $('.multi-lang').click(function(e){
    //     e.stopPropagation();
    //     $('.desple-mi-cuenta').slideUp(function() {
    //         $('.desple-notificaciones').slideUp(function() {
    //             $('.desple-carrito').slideUp(function() {
    //                 $('.desple-multi-lang').animate({height: "toggle"}, 300);
    //             });
    //         });
    //     });
    // });
/*
    $('.carrito').click(function(e){
        e.stopPropagation();
        $('.desple-carrito').animate({height: "toggle"}, 300);
    });
*/
    $('.notificacion').click(function(e){
        e.stopPropagation();
        $('.desple-mi-cuenta').slideUp(function() {
            $('.desple-carrito').slideUp(function() {
                $('.desple-multi-lang').slideUp(function() {
                    $('.desple-notificaciones').animate({height: "toggle"}, 300);
                });
            });
        });
    });

    $('.img-usuario').click(function(){
        $('.desple-multi-lang').animate({height: "hide"}, 300);
                $('.desple-mi-cuenta').animate({height: "toggle"}, 300);

    });

    $('.multi-lang').click(function(){
        $('.desple-mi-cuenta').animate({height: "hide"}, 300);
        $('.desple-multi-lang').animate({height: "toggle"}, 300);
    });
    // $('.img-usuario').click(function(){
    //     $('.desple-notificaciones').slideUp(function() {
    //         $('.desple-carrito').slideUp(function() {
    //             $('.desple-mi-cuenta').animate({height: "toggle"}, 300);
    //         });
    //     });
    // });

    $('body').on("click", function () {
        var clases_plegar = ['.desple-carrito','.desple-notificaciones','.desple_otras_ciudades'];

        clases_plegar.forEach(function(clase) {
            if ($(clase).css('display') != 'none') {
                $(clase).slideUp();
            }
        });

/*
        if ($('.desple-carrito').css('display') != 'none') {
            $('.desple-carrito').slideUp();
        }
        if ($('.desple_otras_ciudades').css('display') != 'none') {
            $('.desple_otras_ciudades').slideUp();
        }*/
    });

    $('.scroll').bind('mousewheel', function(e, d) {
        var height =  $(this).height();
        var scrollHeight =  $(this).prop('scrollHeight');
        console.log(this.scrollTop+" "+height+" "+scrollHeight);
        if((this.scrollTop >= (scrollHeight - height) && d < 0) || (this.scrollTop === 0 && d > 0)) {
            e.preventDefault();
        }
    });

    $('.scroll2').bind('mousewheel', function(e, d) {
        var height =  $(this).height();
        var scrollHeight =  $(this).prop('scrollHeight');
        console.log(this.scrollTop+" "+height+" "+scrollHeight);
        if((this.scrollTop >= (scrollHeight - height) && d < 0) || (this.scrollTop === 0 && d > 0)) {
            e.preventDefault();
        }
    });
});



(function ($) {
    // your code
   this.desplegar_carrito =  function (desplegar){
        if (desplegar){
            if ($('.desple-carrito').css('display')=='none'){
                $('.desple-mi-cuenta').slideUp(function() {
                    $('.desple-notificaciones').slideUp(function() {
                        $('.desple-carrito').animate({height: "toggle"}, 300);
                    });
                });
            }
            if ($('#desplegable-xs').css('margin-right')=='0px'){
                $('#desplegable-xs').animate({"margin-right": '160px'});
            }
        }else{
            //$('.desple-carrito').animate({height: 0}, 300);
        }
    };


    this.desplegar_submenu = function (desplegar){
        if (desplegar){
            if ($('.desplegable-xs').css('display')=='none'){
                $('.desple-mi-cuenta').slideUp(function() {
                    $('.desple-notificaciones').slideUp(function() {
                        $('.desplegable-xs').animate({height: "toggle"}, 300);
                    });
                });
            }
            if ($('#desplegable-xs').css('margin-right')=='0px'){
                $('#desplegable-xs').animate({"margin-right": '160px'});
            }
        }else{
            //$('.desple-carrito').animate({height: 0}, 300);
        }
    };

   return this;

})(jQuery);

