(function(window) {

  'use strict';

  /**
   * Extend Object helper function.
   */
  function extend(a, b) {
    for(var key in b) { 
      if(b.hasOwnProperty(key)) {
        a[key] = b[key];
      }
    }
    return a;
  }

  /**
   * Each helper function.
   */
  function each(collection, callback) {
    for (var i = 0; i < collection.length; i++) {
      var item = collection[i];
      callback(item);
    }
  }

  /**
   * Menu Constructor.
   */
  function Menu(options) {
    this.options = extend({}, this.options);
    extend(this.options, options);
    this._init();
  }

  /**
   * Menu Options.
   */
  Menu.prototype.options = {
    wrapper: '#o-wrapper',          // The content wrapper
    type: 'slide-left',             // The menu type
    menuOpenerClass: '.c-button',   // The menu opener class names (i.e. the buttons)
    maskId: '#c-mask'               // The ID of the mask
  };

  /**
   * Initialise Menu.
   */
  Menu.prototype._init = function() {
    this.body = document.body;
    this.wrapper = document.querySelector(this.options.wrapper);
    this.mask = document.querySelector(this.options.maskId);
    this.menu = document.querySelector('#c-menu--' + this.options.type);
    this.menu_desplegable = document.querySelector('#menu-desplegable');
    this.closeBtn = this.menu_desplegable != null ? this.menu_desplegable.querySelector('.c-menu__close') : null;
    this.menuOpeners = document.querySelectorAll(this.options.menuOpenerClass);
    this._initEvents();
  };

  /**
   * Initialise Menu Events.
   */
  Menu.prototype._initEvents = function() {
      if (this.closeBtn == null || this.mask == null) return;
    // Event for clicks on the close button inside the menu.
    this.closeBtn.addEventListener('click', function(e) {
      e.preventDefault();
      this.close();
    }.bind(this));

    // Event for clicks on the mask.
    this.mask.addEventListener('click', function(e) {
      e.preventDefault();
      this.close();
    }.bind(this));
  };

  /**
   * Open Menu.
   */
  Menu.prototype.open = function() {
    this.body.classList.add('has-active-menu');
    this.wrapper.classList.add('has-' + this.options.type);
    this.menu.classList.add('is-active');
    this.mask.classList.add('is-active');
    slideLeftBtn.classList.add('hide');
    this.closeBtn.classList.remove('hide');
    this.disableMenuOpeners();
  };

  /**
   * Close Menu.
   */
  Menu.prototype.close = function() {
    this.body.classList.remove('has-active-menu');
    this.wrapper.classList.remove('has-' + this.options.type);
    this.menu.classList.remove('is-active');
    this.mask.classList.remove('is-active');
    slideLeftBtn.classList.remove('hide');
    this.closeBtn.classList.add('hide');
    
    this.enableMenuOpeners();
  };

  /**
   * Disable Menu Openers.
   */
  Menu.prototype.disableMenuOpeners = function() {
    each(this.menuOpeners, function(item) {
      item.disabled = true;
    });
  };

  /**
   * Enable Menu Openers.
   */
  Menu.prototype.enableMenuOpeners = function() {
    each(this.menuOpeners, function(item) {
      item.disabled = false;
    });
  };

  /**
   * Add to global namespace.
   */
  window.Menu = Menu;

})(window);


/**
 * Menu Slide left instantiation and action.
 */
var slideLeft = new Menu({
    wrapper: '#o-wrapper',
    type: 'slide-left',
    menuOpenerClass: '.c-button',
    maskId: '#c-mask'
});

var slideLeftBtn = document.querySelector('#c-button--slide-left');

if (slideLeftBtn != null) {
    slideLeftBtn.addEventListener('click', function(e) {
        e.preventDefault();
        slideLeft.open();
    });
}

$('.menu_desple').click(function() {
    $(this).next().slideDown();
    var activa = $(this).hasClass('activa');
    $(this).children().removeClass('ui-2_square-add-08');
    $(this).children().removeClass('gris');
    $(this).children().addClass('ui-2_square-delete-10');
    $(this).removeClass('negro');
    $(this).addClass('rojo');

    $('.activa').next().slideUp();
    $('.activa').children().addClass('ui-2_square-add-08');
    $('.activa').children().addClass('gris');
    $('.activa').children().removeClass('ui-2_square-delete-10');
    $('.activa').addClass('negro');
    $('.activa').removeClass('rojo');
    $('.activa').removeClass('activa');

    if (!activa) {
        $(this).parent().next().slideDown();
        $(this).addClass("activa");
    }
});

$('.btn-desplegable').click(function(e) {
    e.preventDefault();
    if ($('#desplegable-xs').css('margin-right') == null || $('#desplegable-xs').css('margin-right') === '0px')
        $('#desplegable-xs').animate({"margin-right": '+=160'});
});

$('.cerrar-desplegable-xs').click(function(e) {
    e.preventDefault();
    if ($('#desplegable-xs').css('margin-right') === '160px')
        $('#desplegable-xs').animate({"margin-right": '-=160'});
});